import React from 'react';
import headerStyle from '../../less/header.module.less';
import BxlLogo from '../../images/bxl-logo.png';
import {Link, useIntl} from 'gatsby-plugin-intl';
import CookieBar from "./cookie-bar";
import Navigation from "./navigation";
import BreadcrumbBar from "./breadcrumb-bar";
import {Breadcrumb} from "antd";

const Header = ({
                  author,
                  topMenuItems,
                  data,
                  breadcrumbs,
                  showBreadcrumbs
                }) => {
  const intl = useIntl();

  return (
    <header className={headerStyle.header}>
      <div className={headerStyle.titleBar}>
        <div className={headerStyle.logo}>
          <a href={intl.formatMessage({id: 'link_financesbudgetbrussels'})}
             target="_blank"
             rel="noopener noreferrer">
            <img
              src={BxlLogo}
              srcSet={`${BxlLogo} 2x`}
              alt={intl.formatMessage({id: 'alt_brusselslogo'})}
            />
          </a>
        </div>
        <div className={headerStyle.title}>
          <a href={intl.formatMessage({id: 'link_financesbudgetbrussels'})}
             target="_blank"
             rel="noopener noreferrer">
            <span className={headerStyle.author}>{author}</span>
          </a>
        </div>
      </div>
      <CookieBar/>

      <Navigation logoUrl={data.settings.logo_url}
                  title={intl.translate(data.settings, 'og_title')}
                  menuItems={topMenuItems}/>

      {typeof window !== `undefined` && showBreadcrumbs ? (
        <BreadcrumbBar>
          {breadcrumbs.map((breadcrumb, index) => (
            <Breadcrumb.Item key={index}>
              <Link to={breadcrumb.url}><span
                style={{textTransform: 'capitalize'}}>{breadcrumb.label}</span></Link>
            </Breadcrumb.Item>
          ))}
        </BreadcrumbBar>
      ) : ''}
    </header>
  );
};

export default Header;
