import React, {useEffect, useRef, useState} from 'react';
import useIntl from '../../helpers/useIntl';
import MiniSearch from 'minisearch';
import {navigate} from '@reach/router';
import {AutoComplete, Input} from "antd"

const SearchBar = (props) => {
  const intl = useIntl();
  const {className, searchableBudget} = props;
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (intl.locale && searchableBudget) {
      let stopWords = new Set(['en', 'of', 'in', 'de', 'een']);

      setSearch(new MiniSearch({
        idField: 'category_id',
        fields: ['label_' + intl.locale, 'total'],
        processTerm: (term, _fieldName) =>
          stopWords.has(term) ? null : term.toLowerCase(),
        tokenize: (string, _fieldName) => string.split(' '),
        storeFields: [
          'label_' + intl.locale,
          'category_id',
          'parent',
          'path',
          'hasChildren',
          'mainCategory',
        ],
      }));
    }
  }, [intl.locale, searchableBudget]);

  useEffect(() => {
    if (search && searchableBudget) {
      search.removeAll();
      search.addAll(searchableBudget);
    }
  }, [search, searchableBudget]);

  const getPath = (result) => {
    if (result.hasChildren) {
      return result.path;
    }

    return result.parent.path;
  };

  const onSearch = (searchText) => {
    let results = search.search(searchText, {
      prefix: true,
      fuzzy: 0.1,
    });

    let expenseResults = results.filter(
      (result) => result.mainCategory === 'expenses');
    let revenueResults = results.filter(
      (result) => result.mainCategory === 'revenue');

    let newOptions = [];

    if (revenueResults.length) {
      newOptions.push({
        label: renderTitle(intl.formatMessage({id: 'general_revenue'})),
        options: revenueResults
          .map((result) => {
            return renderItem(result['label_' + intl.locale], getPath(result), result.id);
          }),
      });
    }

    if (expenseResults.length) {
      newOptions.push({
        label: renderTitle(intl.formatMessage({id: 'general_expenses'})),
        options: expenseResults
          .map((result) => {
            return renderItem(result['label_' + intl.locale], getPath(result), result.id);
          }),
      });
    }

    setOptions(newOptions);
  }

  const onFocus = () => {
    onSearch('a');
  }

  const onSelect = (title, {path}) => {
    navigate(`/${intl.locale}/home/${path}/`);
  };

  const noResultTitle = intl.formatMessage({id: 'searchbar_no_result_title'});
  const searchSuggestionText = intl.formatMessage({id: 'searchbar_no_result_suggestions'});

  const renderSuggestions = (title, text) => {
    return (
      <div role={"region"}
           id="suggestions"
           tabIndex={0}
           aria-live={"polite"}
           style={{display: 'flex', flexFlow: 'column', color: 'black'}}>
        <b>{title}</b>
        <span style={{
          fontSize: '12px',
          wordBreak: 'wrap'
        }}>{text}</span>
      </div>
    );
  };

  const suggestionInfo = renderSuggestions(noResultTitle, searchSuggestionText);

  const renderTitle = (title) => {
    return (
      <span>
       {title}
      </span>
    );
  };

  const renderItem = (title, path, key) => {
    return {
      path,
      key,
      value: title,
      label: (
        <a href="#">
          {title}
        </a>
      ),
    };
  };

  return (
    <AutoComplete
      aria-label={intl.formatMessage({id: 'searchbar_arialabel'})}
      aria-controls={"suggestions"}
      className={`${className}`}
      dropdownMatchSelectWidth={340}
      style={{width: 250, right: 20, textAlign: "left"}}
      notFoundContent={suggestionInfo}
      onFocus={onFocus}
      onSelect={onSelect}
      onSearch={onSearch}
      options={options}>
      <Input.Search enterButton
                    aria-label={intl.formatMessage({id: "searchbar_arialabel"})}
                    placeholder={intl.formatMessage({id: "searchbar_placeholder"})}
                    size="large"/>
    </AutoComplete>
  );
};

export default SearchBar;
