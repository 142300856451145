import React from 'react';
import Rainbowbar from './rainbowbar';
import {IntlContextConsumer, Link} from 'gatsby-plugin-intl';
import useIntl from '../../helpers/useIntl';
import Container from './container';
import FooterStyle from '../../less/footer.module.less';
import Twitter from '../../images/twitter.svg';
import Facebook from '../../images/facebook.svg';
import TreeCompanyLogo from '../../images/TreeCompany_LogoGreen.svg';
import Linkedin from '../../images/linkedin.svg';
import Youtube from '../../images/youtube.svg';
import AnysurferLabel from '../../images/label_anysurfer_40x40.png'
import PropTypes from "prop-types";
import BxlLogoFr from "../../images/financesBudgetBrusselsLogo-fr.svg";
import BxlLogoNl from "../../images/financesBudgetBrusselsLogo-nl.svg";

const Footer = props => {
  const intl = useIntl();
  const {menuItems} = props;

  return (
    <footer role="contentinfo">
      <section className={FooterStyle.header}>
        <Container size={'small'}>
          <div className={FooterStyle.headerContent}>
            <a
              href={intl.formatMessage({id: 'link_financesbudgetbrussels'})}
              target="_blank"
              rel="noopener noreferrer">
              <IntlContextConsumer>
                {({languages, language: currentLocale}) => (
                  <img className={FooterStyle.bxlLogo}
                       src={currentLocale === 'nl' ? BxlLogoNl : BxlLogoFr}
                       alt={intl.formatMessage({id: "alt_financesbudgetbrusselslogo"})}/>
                )}
              </IntlContextConsumer>
            </a>
            <ul className={FooterStyle.logos}>
              <li>
                <a
                  href={intl.formatMessage({id: 'link_twitter'})}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Twitter} alt={'Twitter'}/>
                </a>
              </li>

              <li>
                <a
                  href={intl.formatMessage({id: 'link_facebook'})}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Facebook} alt={'Facebook'}/>
                </a>
              </li>

              <li>
                <a
                  href={intl.formatMessage({id: 'link_linkedin'})}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={Linkedin} alt={'LinkedIn'}/>
                </a>
              </li>

              <li>
                <a
                  href={intl.formatMessage({id: 'link_youtube'})}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={Youtube} alt={'YouTube'}/>
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section>
        <Container size={'small'}>
          <div className={FooterStyle.bottom}>
            <ul className={FooterStyle.items}>
              {menuItems.map((menuItem) => (
                <li key={menuItem.node.id}>
                  <Link
                    to={'/' + menuItem.node.url}>{intl.translate(menuItem.node, 'name')}</Link>
                </li>
              ))}
            </ul>

            <ul className={FooterStyle.items}>
              <li>
                <a href={intl.formatMessage({id: 'footer_anysurfer_link'})}
                   target="_blank"
                   rel="noopener noreferrer">
                  <img src={AnysurferLabel}
                       alt={intl.formatMessage({id: 'footer_anysurfer_alt'})}
                       title={intl.formatMessage({id: 'footer_anysurfer_title'})}
                  />
                </a>
              </li>
              <li>
                <a
                  href={intl.formatMessage({id: 'link_treecompany'})}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={TreeCompanyLogo} alt='TreeCompany' width="150"/>
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <Rainbowbar/>
    </footer>
  );
};

Footer.propTypes = {
  menuItems: PropTypes.array,
};

Footer.defaultProps = {
  menuItems: [],
};

export default Footer;
