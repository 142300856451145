import React from 'react';
import breadcrumbBarStyle from "../../less/breadcrumb-bar.module.less"
import Container from "./container"
import PropTypes from "prop-types"
import {Breadcrumb} from "antd"
import {Link} from "gatsby-plugin-intl"

const BreadcrumbBar = ({ children }) => (
  <div className={breadcrumbBarStyle.wrapper}>
    <Container>
      <div className={breadcrumbBarStyle.container}>
        <Breadcrumb separator="—" className={breadcrumbBarStyle.breadcrumb}>
          <Breadcrumb.Item><Link to={"/home/"}>Home</Link></Breadcrumb.Item>
          {children}
        </Breadcrumb>
      </div>
    </Container>
  </div>
)

BreadcrumbBar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BreadcrumbBar
