import React from 'react';
import PropTypes from 'prop-types';
import ContainerStyle from '../../less/container.module.less';

const Container = ({ children, size, noPadding }) => (
  <div
    className={`${ContainerStyle.container} ${size} ${
      noPadding ? ContainerStyle.noPadding : ''
    }`}
  >
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

Container.defaultProps = {
  size: 'medium',
  noPadding: false,
};

export default Container;
