/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';
import useIntl from "../../helpers/useIntl";

function SEO({lang, meta}) {
  const intl = useIntl();
  const data = useStaticQuery(
    graphql`
      query {
        settings{
          name_nl
          name_fr
          logo_url
          description_nl
          description_fr
          og_description_fr
          og_description_nl
          og_image_url_fr
          og_image_url_nl
          og_title_fr
          og_title_nl
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{lang}}
      meta={[
        {
          name: `description`,
          content: intl.translate(data.settings, 'og_description'),
        },
        {
          property: `og:title`,
          content: intl.translate(data.settings, 'og_title'),
        },
        {
          property: `og:image`,
          content: intl.translate(data.settings, 'og_image_url'),
        },
        {
          property: `og:description`,
          content: intl.translate(data.settings, 'og_description'),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: intl.translate(data.settings, 'name'),
        },
        {
          name: `twitter:title`,
          content: intl.translate(data.settings, 'og_title'),
        },
        {
          name: `twitter:description`,
          content: intl.translate(data.settings, 'og_description'),
        },
      ].concat(meta)}
    >

    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
