import React from "react"
import {defineMessages, useIntl} from "react-intl";
import Rainbowbar from "./rainbowbar";
import CookieConsent from "react-cookie-consent";

const CookieBar = props => {
  const intl = useIntl();
  const translatedMessage = defineMessages({
    id: "cookie_moreinfo",
  })

  return (
    <CookieConsent
      button
      declineButtonText={intl.formatMessage({id: 'cookie_cancel'})}
      buttonText={intl.formatMessage({id: 'cookie_accept'})}
      enableDeclineButton={true}
      cookieName="gatsby-gdpr-google-analytics"
      style={{
        padding: '0 20px',
        position: 'relative',
        background: 'white',
        color: 'black',
        boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
      disableButtonStyles={true}
      declineButtonStyle={{
        marginTop: '20px',
        marginRight: '20px',
        marginBottom: '10px',
      }}
      disableStyles={true}
      buttonStyle={{
        marginBottom: '20px'
      }}
      buttonClasses={'ant-btn ant-btn-primary'}
      declineButtonClasses={'ant-btn ant-btn-primary'}
      expires={150}
      ariaAcceptLabel={intl.formatMessage({id: 'cookie_accept'})}
      ariaDeclineLabel={intl.formatMessage({id: 'cookie_cancel'})}>
      <div style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
      }}>
        <Rainbowbar/>
      </div>
      <div style={{marginRight: '20px'}}>
          <span dangerouslySetInnerHTML={{
            __html: intl.formatMessage(translatedMessage, {
              x: `<a href=${intl.formatMessage({id: 'link_privacy'})}>${intl.formatMessage({id: 'cookie_privacy'})} </a>`
            })
          }}/>
      </div>
    </CookieConsent>
  )
}

export default CookieBar;
