/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';
import Header from './header';
import Footer from './footer';
import '../../less/main.module.less';
import 'focus-visible';
import {changeLocale} from 'gatsby-plugin-intl';
import useIntl from '../../helpers/useIntl';
import browserLang from 'browser-lang';
import {Helmet} from 'react-helmet/es/Helmet';
import Hero from './hero';
import SEO from './seo';

const Layout = ({
                  children,
                  heroFluidImage,
                  heroFixedImage,
                  heroBgColor,
                  heroTitle,
                  heroSubtitle,
                  heroAltText,
                  searchableBudget,
                  isMobile,
                  heroTextColor,
                  titleOffset,
                  showHero,
                  heroSize,
                  showBreadcrumbs,
                  breadcrumbs,
                  isMarked,
                }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      settings{
        name_nl
        name_fr
        og_title_nl
        og_title_fr
        logo_url
      }
      allMenuItem {
        edges {
          node {
            id
            name_nl
            name_fr
            url
            top_menu
            bottom_menu
          }
        }
      }
    }
  `);
  const intl = useIntl();
  const allMenuItems = data.allMenuItem.edges;
  const topMenuItems = allMenuItems.filter((menuItem) => menuItem.node.top_menu);
  const bottomMenuItems = allMenuItems.filter(
    (menuItem) => menuItem.node.bottom_menu);
  const pageTitle = `${heroTitle} | ${intl.translate(data.settings, 'name')}`;

  if (!intl.locale) {
    changeLocale(browserLang());
  }

  return (
    <div style={{
      display: 'flex',
      flexFlow: 'column',
      minHeight: '100vh',
    }}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SEO lang={intl.locale}/>
      <Header author={intl.translate(data.settings, 'name')}
              topMenuItems={topMenuItems} showHero={showHero}
              data={data}
              breadcrumbs={breadcrumbs}
              showBreadcrumbs={showBreadcrumbs}
      />
      <main style={{
        display: 'flex',
        flexFlow: 'column',
        flexGrow: '1',
      }}>
        {showHero ? (
          <Hero
            isMobile={isMobile}
            isMarked={isMarked}
            titleOffset={titleOffset}
            hasSearch={!!searchableBudget}
            searchableBudget={searchableBudget}
            fluidImage={heroFluidImage}
            fixedImage={heroFixedImage}
            bgColor={heroBgColor}
            title={heroTitle}
            textColor={heroTextColor}
            subtitle={heroSubtitle}
            altText={heroAltText}
            size={heroSize}
          />
        ) : ''}
        {children}
      </main>
      <Footer menuItems={bottomMenuItems}/>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  heroFixedImage: PropTypes.string,
  heroFluidImage: PropTypes.object,
  heroBgColor: PropTypes.string,
  heroTitle: PropTypes.string,
  heroSubtitle: PropTypes.string,
  heroAltText: PropTypes.string,
  heroTextColor: PropTypes.string,
  showHero: PropTypes.bool,
  heroSize: PropTypes.string,
  showBreadcrumbs: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  titleOffset: PropTypes.bool,
  isMarked: PropTypes.bool,
};

Layout.defaultProps = {
  showHero: true,
  heroBgColor: 'white',
  heroSize: '',
  heroTextColor: 'var(--color-primary)',
  showBreadcrumbs: false,
  breadcrumbs: [],
  titleOffset: false,
  heroHasSearch: false,
  isMarked: false,
};

export default Layout;
