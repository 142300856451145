import React, {useState} from 'react';
import {Button, Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {changeLocale} from 'gatsby-plugin-intl';
import DropdownStyle from "../../less/dropdown.module.less"

function LocaleSelection(props) {
  const [localeNames] = useState({
    nl: 'NL',
    fr: 'FR',
  });

  const [dropdownAria, setDropdownAria] = useState(false);

  const menu = (
    <ul className={DropdownStyle.menu}>
      {props.localeSlugs.map((localeSlug, index) => (
        <li onClick={() => changeLocale(localeSlug)} key={index}>
          <a href="#" className={DropdownStyle.item}>
            {localeSlug}
          </a>
        </li>
      ))}
    </ul>
  );

  if (props.isDropdown) {
    return (
      <>
        <Dropdown overlay={menu}
                  trigger={['click']}
                  onVisibleChange={(collapsed) => setDropdownAria(collapsed)}
                  getPopupContainer={triggerNode => triggerNode.parentNode}>
          <button className="ant-dropdown-link"
                  aria-expanded={dropdownAria}
                  onClick={(e) => e.preventDefault()}>
            <span>{localeNames[props.activeLocaleSlug]}</span>
            <DownOutlined role={''} aria-label={''}/>
          </button>
        </Dropdown>
      </>
    );
  } else {
    return props.localeSlugs.map(localeSlug => (
      <Button
        key={localeSlug}
        type={props.activeLocaleSlug === localeSlug ? 'primary' : ''}
        onClick={() => changeLocale(localeSlug)}
      >
        {localeNames[localeSlug]}
      </Button>
    ));
  }
}

LocaleSelection.propTypes = {
  localeSlugs: PropTypes.array,
  activeLocaleSlug: PropTypes.string,
  isDropdown: PropTypes.bool,
};

LocaleSelection.defaultProps = {
  activeLocaleSlug: 'nl',
  isDropdown: true,
};

export default LocaleSelection;
