import React, {useEffect, useRef, useState} from 'react';
import navigationStyle from '../../less/navigation.module.less';
import {Button} from 'antd';
import {IntlContextConsumer, Link} from 'gatsby-plugin-intl';
import Container from './container';
import LocaleSelection from './locale-selection';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import useIntl from '../../helpers/useIntl';
import PropTypes from 'prop-types';

function Navigation(props) {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const toggleMobileMenu = function () {
    document.body.style.overflow = mobileMenuActive ? '' : 'hidden';
    setMobileMenuActive(!mobileMenuActive);
  };

  const useWindowUnloadEffect = (handler, callOnCleanup) => {
    const cb = useRef();

    cb.current = handler;

    useEffect(() => {
      if (typeof window !== `undefined`) {
        const handler = () => cb.current();

        window.addEventListener('beforeunload', handler);

        return () => {
          if (callOnCleanup) handler();

          window.removeEventListener('beforeunload', handler);
        };
      }
    }, [cb]);
  };

  useWindowUnloadEffect(() => {
    if (mobileMenuActive) {
      toggleMobileMenu();
    }
  }, true);

  const intl = useIntl();
  const {menuItems, logoUrl} = props;

  return (
    <div>
      <Container>
        <div className={navigationStyle.wrapper}>
          <div className={navigationStyle.logo}>
            <Link to="/home/">
              <img
                src={logoUrl}
                alt={intl.formatMessage({id: 'alt_projectlogo'})}
                srcSet={`${logoUrl} 2x`}
              />
            </Link>
          </div>
          <div className={navigationStyle.controls}>
            <nav role="navigation">
              <ul className={navigationStyle.navigation}>
                {menuItems.map((menuItem) => (
                  <li key={menuItem.node.id}>
                    <Link to={'/' + menuItem.node.url}
                          className={'ant-btn ant-btn-link'}>
                      {intl.translate(menuItem.node, 'name')}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            <IntlContextConsumer>
              {({languages, language: currentLocale}) => (
                <LocaleSelection
                  localeSlugs={languages}
                  activeLocaleSlug={currentLocale}
                  isDropdown={true}
                />
              )}
            </IntlContextConsumer>
          </div>
          <div className={navigationStyle.mobileControls}>
            <Button
              onClick={toggleMobileMenu}
              aria-pressed={mobileMenuActive}
              aria-haspopup={true}
              aria-controls="mobile-menu"
              icon={
                mobileMenuActive ? (
                  <CloseOutlined style={{fontSize: '20px'}} role={''}
                                 aria-label={''}/>
                ) : (
                  <MenuOutlined style={{fontSize: '20px'}} role={''}
                                aria-label={''}/>
                )
              }
              type="link"
            />
          </div>
        </div>
      </Container>
      <nav
        id='mobile-menu'
        className={
          mobileMenuActive
            ? `${navigationStyle.mobileContainer} ${navigationStyle.mobileContainerOpen}`
            : navigationStyle.mobileContainer
        }
      >
        <ul className={navigationStyle.mobileNavigation}>
          {menuItems.map((menuItem) => (
            <li key={menuItem.node.id}>
              <Link to={'/' + menuItem.node.url}
                    className={'ant-btn ant-btn-link'}>
                {intl.translate(menuItem.node, 'name')}
              </Link>
            </li>
          ))}
        </ul>

        <div className={navigationStyle.mobileLanguageContainer}>
          <IntlContextConsumer>
            {({languages, language: currentLocale}) => (
              <LocaleSelection
                localeSlugs={languages}
                activeLocaleSlug={currentLocale}
                isDropdown={false}
              />
            )}
          </IntlContextConsumer>
        </div>
      </nav>
    </div>
  );
}

Navigation.propTypes = {
  title: PropTypes.string,
  menuItems: PropTypes.array
};

Navigation.defaultProps = {
  title: ``,
  menuItems: [],
};

export default Navigation;
