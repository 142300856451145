import React from 'react';
import rainbowStyle from '../../less/rainbowbar.module.less';

const Rainbowbar = () => (
  <div className={rainbowStyle.rainbowbar}>
    <div className={rainbowStyle.rainbow1}></div>
    <div className={rainbowStyle.rainbow2}></div>
    <div className={rainbowStyle.rainbow3}></div>
    <div className={rainbowStyle.rainbow4}></div>
    <div className={rainbowStyle.rainbow5}></div>
    <div className={rainbowStyle.rainbow6}></div>
    <div className={rainbowStyle.rainbow7}></div>
  </div>
);

export default Rainbowbar;
