import React, {useEffect} from 'react';
import HeroStyle from '../../less/hero.module.less';
import Container from './container';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import SearchBar from './searchbar';

const Hero = ({
                isMobile,
                fluidImage,
                fixedImage,
                bgColor,
                title,
                subtitle,
                searchableBudget,
                altText,
                textColor,
                titleOffset,
                size,
                hasSearch,
                isMarked,
              }) => {
  const ref = React.createRef();

  useEffect(() => {
    ref.current.style.setProperty('--hero-bg', bgColor);
  });

  const sources = fluidImage
    ? [
      fluidImage.mobile.fluid,
      {
        ...fluidImage.desktop.fluid,
        media: `(min-width: 491px)`,
      },
    ]
    : [];

  return (
    <div
      className={HeroStyle.hero}
      ref={ref}
      style={{
        backgroundColor: bgColor,
        maxWidth: sources.length ? '1490px' : 'auto',
        height: size === 'small' ? '200px' : '280px',
      }}
    >
      {/* center absolutely positioned img */}
      <div
        style={{
          position: 'absolute',
          left: '0',
          right: '0',
          top: '0',
          bottom: '0',
        }}
      >
        {fixedImage ? (
          <>
            <div className={HeroStyle.fade}/>
            <div style={{
              backgroundImage: `url(${fixedImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: '100%',
            }}></div>
          </>
        ) : ''}

        {fluidImage ? (
          <>
            <div className={HeroStyle.fade}/>
            <BackgroundImage
              Tag="section"
              alt={altText}
              style={{
                height: '100%',
                width: '100%',
                backgroundSize: 'cover',
              }}
              fluid={sources}
              backgroundColor={`#040e18`}
            />
          </>
        ) : ''}
      </div>
      <Container>
        <div className={HeroStyle.text}
             style={{bottom: titleOffset ? '20px' : '0'}}>
          <h1 className={HeroStyle.title + ' h2'}
              style={{
                color: textColor,
                margin: 0,
                backgroundColor: isMarked ? '#ffffff' : '',
                padding: isMarked ? '2px 10px' : 0,
              }}>{title}</h1>
          {subtitle && (
            <span className={HeroStyle.subtitle}
                  style={{
                    color: textColor,
                    backgroundColor: isMarked ? '#ffffff' : '',
                    padding: isMarked ? '2px 10px' : 0,
                  }}>{subtitle}</span>
          )}
          {hasSearch ? (
            <div className={HeroStyle.search}
                 style={{bottom: isMobile ? '0' : '70px'}}>
              <SearchBar searchableBudget={searchableBudget}/>
            </div>
          ) : null}

        </div>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  fixedImage: PropTypes.string,
  fluidImage: PropTypes.object,
  bgColor: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  altText: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.string,
  titleOffset: PropTypes.bool,
  isMobile: PropTypes.bool,
  hasSearch: PropTypes.bool,
  isMarked: PropTypes.bool,
};

Hero.defaultProps = {
  size: '',
  altText: '',
  titleOffset: false,
  isMobile: false,
  hasSearch: false,
  isMarked: false,
};

export default Hero;
